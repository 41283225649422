/* Custom Fonts*/
@font-face {
  font-family: Centra;
  src: url('assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}


/* Default CSS */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}


body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}


nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  opacity: 1;
  transition: 0.32 ease-in-out;
}

nav.navbar h3{
  color: white;
  font-family: 'Centra';
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 3px;
  font-style: normal;
  text-shadow: 4px 4px #121212;
}

nav.navbar.scrolled {
  padding: 5px 0;
  background-color: #121212;
  opacity: 0.9;
  border-color: #fff;
  border-bottom: solid;
  border-bottom-width: 2px;
}

nav.navbar a.navbar-brand{
  width: 15%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0px 25px;
  font-size: 18px;
  opacity: 0.75;
  position: relative;
  
}

@media only screen and (max-width:991px){
   .nav-link.navbar-link {
    margin-bottom: 20px;
  }
}

nav.navbar .navbar-nav .nav-link.navbar-link::after {
  content: '';
  opacity: 0.2;
  height: 2px;
  width: 0%;
  background-color: #ffffff;
  position: absolute;
  bottom: -6px;
  left: 0;
  
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover::after {
  width: 100%;
  opacity: 1;
  transition: ease-in-out 0.5s;
 }


@media only screen and (max-width:991px){
  nav.navbar .navbar-nav .nav-link.navbar-link:hover::after {
    width: 0%;
   }
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link:active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background-color: rgba(217,217,217,0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255,255,255,0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img{
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(100);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width:991px){
  span.navbar-text {
    display:inline;
    padding-bottom: 10px;
  }
}

.navbar-text button span{
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/gb-milky.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  border-bottom: white;
  border-bottom-style: solid;
}

.banner .tagline{
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: none;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

@media only screen and (max-width:991px){
  .banner .tagline{
    padding: 50px;
    font-size: 24px;
    margin-top: 40px;
   }
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p{
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  border: none;
  background-color: transparent;
  color: white;
  font-weight: 700;
  font-size: 26px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.banner button a,
.banner button a:link,
.banner button a:visited,
.banner button a:hover,
.banner button a:active {
  text-decoration: none;
  color: #fff;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-color: #ffffff;
  border-width: 5px;
  margin-left: 0px;
  animation: updown 4s infinite;
}

@media only screen and (max-width:767px){
  .banner img{
    margin-top: 30px;
   }
}

@keyframes updown {
  0% {
    transform: translateY(-5px)
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-5px);
  }
  
}


.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* Skills */

.skill{
  padding: 200px 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #292626;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  border-style: solid;
}

.skill h2{
  font-size: 45px;
  font-weight: 700;
}

.skill p{
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider{
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img{
  width: 50%;
  margin: 10px auto 15px auto;

}

.skill-slider .item img:hover{
  scale: 1.1;
}

.background-image-left {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: -4;
  margin-top: -1000px;
}


/* Projects */
.project {
  padding: 80px 0;
  position: relative;
}

.project-bx {
  background: #292626;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  border-style: solid;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
}

.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills{
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(255 255 255 / 10%);
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
  border-style: solid;
  border-width: 1px;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position:relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  border-style: solid;
  border-width: 1px;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #137986 -5.91%, #03a783 150%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255,255,255,0.5);
  border-bottom: 1px solid rgba(255,255,255,0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 0px 55px 55px 0px;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;

}

.proj-imgbx img {
  object-fit: fill;
  background-color: #137986;
  width: 100%;
  height: 400px;
  
}

.proj-imgbx::before{
  content: "";
  background: linear-gradient(90.21deg, #137986 -5.91%, #03a783 150%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before{
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4{
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  text-decoration:underline;
  color: white;
  text-decoration-color: white;
}

.proj-txtx span{
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* Education */

.education{
  padding: 100px 0 50px 0;
  position: relative;
}

.education-bx {
  background: #292626;
  text-align: center;
  padding: 60px 0px;
  border-radius: 64px;
  border-style: solid;
  
}

.education h2{
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 50px;
}

.education p{
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.education-slider{
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.education-caption h3{
  margin-bottom: -50px;
}

.background-image-left2 {
  top: 20%;
  position: absolute;
  bottom: -500px;
  width: 100%;
  right: 0;
  z-index: -4;
  margin-top: -1500px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
